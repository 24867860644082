<template>
  <div
    class="uk-form-stacked"
  >
    <div
      class="uk-child-width-1-1@s uk-grid-small"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-3@l">
        <label class="uk-form-label">VCF Code or Facility Name</label>
        <div class="uk-form-controls">
          <input
            v-model="vcf_code"
            name="vcf_code"
            class="uk-input"
            type="text"
            placeholder="Search VCF Code or Facility Name"
            autocomplete="off"
          >
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-5@l">
        <div class="uk-form-controls uk-position-relative withlabel">
          <label class="uk-form-label">Status</label>
          <select
            v-model="status"
            name="status"
            class="uk-width-expand custom-select"
            placeholder="Status"
          >
            <option
              v-for="(item, key) in statusFilter"
              :key="key"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
          <img
            :src="`${images}/icon/ic_chevron_down.svg`"
            alt="ic_chevron"
          >
        </div>
      </div>
      <div class="uk-width-1-1@s uk-width-1-4@l uk-flex uk-flex-bottom">
        <button
          class="uk-button uk-button-primary uk-margin-small-right uk-padding-small"
          @click.prevent="handleSearch"
        >
          <img
            :src="`${images}/icon/ic_search_white.svg`"
            class="uk-margin-small-right"
            alt=""
            loading="lazy"
          >
          Search
        </button>
        <button
          class="uk-button uk-button-default uk-padding-remove"
          @click.prevent="handleReset"
        >
          Reset Filter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { STATUS_FILTER } from '@/utils/constant'

export default ({
  props: {
    images: {
      required: true,
      type: String
    },
    filterPlantation: {
      required: true,
      type: Function
    },
    resetPlantation: {
      required: true,
      type: Function
    },
    metaFilter: {
      required: false,
      default: null,
      type: Object
    }
  },
  data() {
    return{
      vcf_code: '',
      status: null,
      statusFilter: STATUS_FILTER
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'plantation/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setMetaFilter: "plantation/SET_META_FILTER"
    }),
    handleSearch() {
      this.setMetaFilter({
        ...this.metaFilter,
        search: this.vcf_code,
        status: this.status
      })
      this.filterPlantation()
    },
    handleReset() {
      this.vcf_code = ''
      this.status = null
      this.setMetaFilter({
        ...this.metaFilter,
        search: null,
        status: null
      })
      this.resetPlantation()
    }
  }
})
</script>
