<template>
  <div>
    <header-filter-plantation
      :images="images"
      :filter-plantation="filterPlantation"
      :reset-plantation="resetPlantation"
      :meta-filter="metaFilter"
    />
    <content-table-plantation
      :images="images"
      :list-plantation="listPlantation"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
    />
    <pagination
      :total-data="totalData"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="currentPage"
      :current-limit="currentLimit"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import HeaderFilterPlantation from './HeaderFilter'
import ContentTablePlantation from './ContentPlantation'
import Pagination from '@/components/globals/Pagination'

export default {
  components: {
    HeaderFilterPlantation,
    ContentTablePlantation,
    Pagination
  },
  props: {
    params: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      totalData: 10,
      currentPage: 1,
      currentLimit: 10
    }
  },
  computed: {
    ...mapGetters({
      listPlantation: 'plantation/list_plantation',
      metaFilter: 'plantation/meta_filter',
      getToggleModalAddEditDiscard: 'plantation/get_toggle_modal_add_edit_discard'
    })
  },
  watch: {
    listPlantation(data) {
      this.totalData = data.meta.total
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      setTimeout(() => {
        this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
      }, 2000)
    }
  },
  async mounted () {
    await this.actionGetListPlantation(this.params)
  },
  methods: {
    ...mapActions({
      actionGetListPlantation: 'plantation/getListPlantation'
    }),
    ...mapMutations({
      setMetaFilter: "plantation/meta_filter",
      setToggleModalAddEditDiscard: 'plantation/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    }),
    async filterPlantation() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListPlantation({...this.metaFilter, page: 1, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async resetPlantation() {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = 1
      this.currentLimit = 10
      await this.actionGetListPlantation({ ...this.metaFilter, page: this.currentPage, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changePage(page) {
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      this.currentPage = parseInt(page)
      await this.actionGetListPlantation({...this.metaFilter, page, limit: this.currentLimit})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    },
    async changeLimit(event) {
      this.currentLimit = event.target.value
      this.currentPage = 1
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: true})
      await this.actionGetListPlantation({...this.metaFilter, limit: event.target.value, page: 1})
      this.setToggleModalAddEditDiscard({...this.getToggleModalAddEditDiscard, loadingTable: false})
    }
  }
}
</script>

<style scoped>
.uk-margin-top {
  margin-top: 24px !important;
}
.disabled {
  cursor: no-drop;
}
</style>
